import {mapActions, mapGetters} from 'vuex'
import Filters from '@/components/common/filter/Filters'
import MiniFilter from '@/components/common/filter/MiniFilter'
export default {
    name: 'listMixin',
    components: { Filters, MiniFilter },
    data() {
        return {
            page: 1,
            pageSize: 40,
            list: null,
            data: {},
            identifier: 0,

            opt: {
                cateCode: [
                    { text: '전체', value: null },
                    { text: '꼬리TIP', value: 10 },
                    { text: '연구일지', value: 20 },
                    { text: '꼬뮤니티', value: 80 },
                    { text: 'FAQ', value: 5012 },
                ],
                petCode: [
                    { text: '강아지', value: 10 },
                    { text: '고양이', value: 20 },
                ],
                sort: [
                    { text: '최신순', value: 'new' },
                    { text: '인기순', value: 'score' },
                ],
                activity: [
                    { text: '전체', value: null },
                    { text: '댓글', value: '0' },
                    { text: '답글', value: '1' },
                    { text: '추천', value: '2' },
                ],
                keyword: [],
                disease: [],
            },
            cateCode: null,
            petCode: [],
            activity: null,
            keyword: [],
            disease: [],
            sort: 'new',

            scrollPositions: 0,
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.getBeforeContent(to)
        })
    },
    watch: {
        cateCode() {
            this.search()
        },
        petCode() {
            this.search()
        },
        activity() {
            this.search()
        },
    },
    methods: {
        ...mapActions({
            setMsgPopup: 'common/setMsgPopup',
        }),
        setSort(sort) {
            this.sort = sort
            this.search()
        },
        search() {
            this.page = 1
            this.list = null
            this.identifier++
        },
        async infiniteHandler($state) {
            try {
                let filter = {}
                if (this.cateCode.toString().startsWith('10')) filter = JSON.parse(localStorage.getItem('tipFilter'))
                if (this.cateCode.toString().startsWith('20')) filter = JSON.parse(localStorage.getItem('labFilter'))

                const { result, data } = await this.$api.getBoardList({
                    cateCode: this.cateCode,
                    petCode: this.petCode.join(','),
                    orderBy: this.sort,
                    page: this.page,
                    pageSize: this.pageSize,
                    ...filter,
                })
                if (result === 'success') {
                    if (data.length > 0) {
                        this.list = this.page == 1 ? data : this.list.concat(data)
                        this.page++
                        $state.loaded()
                    }
                    else {
                        $state.complete();
                    }
                }
                else {
                    $state.complete();
                }
            } catch (error) {
                console.log(error);
            }
        },
        setBeforeContent() {
            const beforeContent = {
                routeName: this.$route.name,
                scrollPositions: window.pageYOffset || document.documentElement.scrollTop,
                list: this.list,
                data: this.data,
                opt: this.opt,
                cateCode: this.cateCode,
                petCode: this.petCode,
                activity: this.activity,
                keyword: this.keyword,
                disease: this.disease,
                sort: this.sort,
            }
            sessionStorage.setItem("beforeContent", JSON.stringify(beforeContent))
        },
        getBeforeContent(to) {
            const beforeContent = sessionStorage.getItem("beforeContent")
            if (beforeContent) {
                const beforeContentObj = JSON.parse(beforeContent)
                if (to.name === beforeContentObj.routeName) {
                    this.scrollPositions = beforeContentObj.scrollPositions
                    this.list = beforeContentObj.list
                    this.opt = beforeContentObj.opt
                    this.cateCode = beforeContentObj.cateCode
                    this.petCode = beforeContentObj.petCode
                    this.activity = beforeContentObj.activity
                    this.keyword = beforeContentObj.keyword
                    this.disease = beforeContentObj.disease
                    this.sort = beforeContentObj.sort
                }
            }
            sessionStorage.removeItem('beforeContent')

            setTimeout(() => {
                window.scrollTo({top: this.scrollPositions})
                this.scrollPositions = 0
            }, 300)
        }
    }
}
