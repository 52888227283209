<template>
  <div class="filter-box popup">
    <div class="select-text" @click="openPopup">
      <span>필터</span> <div class="icon-img icon-filter"></div>
    </div>
    <div class="popupBox-wrap scroll" v-if="isOpenPopup">
      <div class="popupPosition"></div>
      <div class="scrollInner">
        <div class="popupBox w-700">

          <div class="popupBox-title">
            필터
            <div class="right-btn" @click="reset"><span class="icon-reset"></span>초기화</div>
          </div>
          <div class="popupBox-body ta-left">

            <template v-if="opt.cateCode.length > 0">
              <div class="filter-title"><h4>카테고리</h4></div>
              <div class="filter-list">
                <label v-for="(item, index) in opt.cateCode" :key="`cateCode-${index}`" class="round-btn">
                  <input type="radio" name="cateCode" :value="item.value" v-model="cateCode" >
                  <span>{{item.text}}</span>
                </label>
              </div>
            </template>

            <template v-if="opt.petCode.length > 0">
              <div class="horizon-bar"></div>
              <div class="filter-title">
                <h4>펫 구분</h4><span class="desc"><span class="color-1">*</span> 중복선택 가능</span>
              </div>
              <div class="filter-list">
                <label v-for="(item, index) in opt.petCode" :key="`petCode-${index}`" class="round-btn">
                  <input type="checkbox" name="petCode" :value="item.value" v-model="petCode" >
                  <span>{{item.text}}</span>
                </label>
              </div>
            </template>

            <template v-if="opt.keyword.length > 0">
              <div class="horizon-bar"></div>
              <div class="filter-title">
                <h4>관심 키워드</h4><span class="desc"><span class="color-1">*</span> 중복선택 가능</span>
              </div>
              <div class="filter-list">
                <label v-for="(item, index) in opt.keyword" :key="`petCode-${index}`" class="round-btn">
                  <input type="checkbox" name="petCode" :value="item.value" v-model="keyword" >
                  <span>{{item.text}}</span>
                </label>
              </div>
            </template>

            <template v-if="opt.disease.length > 0">
              <div class="horizon-bar"></div>
              <div class="filter-title">
                <h4>관심 질환</h4><span class="desc"><span class="color-1">*</span> 중복선택 가능</span>
              </div>
              <div class="filter-list">
                <label v-for="(item, index) in opt.disease" :key="`petCode-${index}`" class="round-btn">
                  <input type="checkbox" name="petCode" :value="item.value" v-model="disease" >
                  <span>{{item.text}}</span>
                </label>
              </div>
            </template>

          </div>
          <div class="popupBox-bottom">
            <button @click="closePopup" class="w-90 mr-10 btn btn-sm btn-outline color-6">{{ $msg('cancel') }}</button>
            <button @click="set" class="w-90 btn btn-sm fw-bold color-1">{{ $msg('apply') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['opt', 'obj'],
  data() {
    return {
      cateCode: null,
      petCode: [],
      keyword: [],
      disease: [],
      isOpenPopup: false,
    }
  },
  methods: {
    set() {
      this.$emit('set', {
        ...this.obj,
        cateCode: this.cateCode,
        petCode: this.petCode,
        keyword: this.keyword,
        disease: this.disease,
      })
      this.closePopup()
    },
    reset() {
      this.cateCode = this.opt.cateCode[0].value
      this.petCode = []
      this.keyword = []
      this.disease = []
    },
    openPopup() {
      if (this.obj.cateCode) this.cateCode = this.obj.cateCode
      if (this.obj.petCode) this.petCode = this.obj.petCode
      if (this.obj.keyword) this.keyword = this.obj.keyword
      if (this.obj.disease) this.disease = this.obj.disease

      document.body.style.overflowY ="hidden"
      this.isOpenPopup = true
    },
    closePopup(){
      document.body.style.overflowY ="scroll"
      this.isOpenPopup = false
    },
  }
}
</script>
