<template>
  <div class="inner-layout-1000">

    <div class="page-title">알림</div>

    <div class="tab-btn-box">
      <div class="tab-btn" :class="{ on : this.filter === 1 }" @click="changeFilter(1)">활동알림</div>
      <div class="tab-btn" :class="{ on : this.filter === 2 }" @click="changeFilter(2)">새로운 소식</div>
    </div>

    <div class="filter-btn-section btn-set" v-if="this.list">
      <div>
        <button class="btn color-6 btn-outline" @click="readAll">전체읽음</button>
        <button class="btn color-6 btn-outline ml-10" @click="confirmRemoveAllPopup">전체삭제</button>
      </div>
    </div>

    <div class="my-notice">

      <div v-if="!this.list" class="noDataMessage2">알림 내역이 없습니다.</div>

      <div>
        <div v-for="listItem in list" :key="`notice-date-${listItem.createDate}`">
          <div class="heading-5">{{ listItem.createDate }}</div>
          <div class="my-notice-list-wrap">
            <div v-if="filter === 1" v-for="item in listItem.list" :key="`notice-${item.id}`" class="my-notice-list-item cursor-pointer" :class="{ active : item.readYN === 0 }" @click="read(item)">
              <div class="icon-notice-list">
                <div :class="setTypeClass(item.type)"></div>
                <div class="icon-text">{{ item.type === 0 ? '댓글' : '답글' }}</div>
              </div>
              <div class="category">{{ item.menuName }}/{{ item.categoryName }}</div>
              <div class="subject">{{ item.title }}</div>
              <div class="user-info">By {{ item.nickName }}  I  {{ $getDateFormat(item.createDate, 'Y.m.d H:i') }}</div>
              <div class="btn-delete" @click.stop="confirmRemovePopup(item.id)"></div>
            </div>

            <div v-if="filter === 2" v-for="item in listItem.list" :key="`notice-${item.id}`" class="my-notice-list-item cursor-pointer" :class="{ active : item.readYN === 0 }" @click="read(item)">
              <div class="icon-notice-list">
                <div :class="setTypeClass(item.type)"></div>
                <div class="icon-text">{{ item.menuName }}</div>
              </div>
              <div class="category">{{ item.menuName }}</div>
              <div class="subject">{{ item.title }}</div>
              <div class="user-info">{{ $getDateFormat(item.createDate, 'Y.m.d H:i') }}</div>
              <div class="btn-delete" @click.stop="confirmRemovePopup(item.id)"></div>
            </div>
          </div>
        </div>

      </div>

      <infinite-loading ref="infiniteLoading" spinner="spiral" :identifier="identifier" @infinite="infiniteHandler">
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex"
import listMixin from "@/mixins/listMixin"
export default {
  name: "notification",
  mixins: [listMixin],
  data() {
    return {
      filter: 1, // 1: 활동알림(notice), 2: 새로운소식(news)
      originalList: null,
    }
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    changeFilter(filter) {
      this.filter = filter
      this.originalList = null
      this.page = 1
      this.list = null
      this.identifier++
    },
    setTypeClass(type) {
      if (this.filter === 1 && type === 0) return 'icon-img icon-comment'
      if (this.filter === 1 && type === 1) return 'icon-img icon-reply'
      if (this.filter === 2 && type === 0) return 'icon-img icon-event'
      if (this.filter === 2 && type === 1) return 'icon-img icon-notice2'
    },
    async infiniteHandler($state) {
      try {
        const { result, list } = await this.$api.getNotificationList({
          target: this.filter,
          page: this.page,
          pageSize: this.pageSize,
        })
        if (result === 'success') {
          if (list && list.length > 0) {
            this.originalList = this.page == 1 ? list : this.originalList.concat(list)
            this.setList()
            setTimeout(() => {
              this.page += 1;
              $state.loaded();
            }, 1000);
          }
          else {
            $state.complete();
          }
        }
        else {
          $state.complete();
        }
      } catch (error) {
        console.log(error);
      }
    },
    setList() {
      const noticeList = this.originalList
      if (noticeList) {
        const dateList = [...new Set(noticeList.map( l => this.$getDateFormat(l.createDate, 'Y.m.d') ))]
        let list = []
        for (const date of dateList) {
          let listItem = {
            createDate: date,
            list: noticeList.filter(l => this.$getDateFormat(l.createDate, 'Y.m.d') === date)
          }
          list.push(listItem)
        }
        this.list = list
      }
      else {
        this.list = null
      }
    },
    async read(item) {
      if (!item.id) return false
      const { result } = await this.$api.readNotification({
        target: this.filter,
        id: item.id,
      })
      if (result === 'success') this.setCount()

      if (this.filter === 2 && item.url) {
        window.open(item.url)
        this.originalList = this.originalList.map(l => {
          if (l.id == item.id) l.readYN = 1
          return l
        })
        this.setList()
      }
      else {
        this.setBeforeContent()
        await this.$router.push(`/${item.menu}/${item.boIdx}`)
      }
    },
    async readAll() {
      const { result } = await this.$api.readNotification({
        target: this.filter,
        id: 0
      })
      if (result === 'success') {
        this.originalList = this.originalList.map(l => {
          if (l.readYN === 0) l.readYN = 1
          return l
        })
        this.setList()
        this.setCount()
      }
    },
    setCount() {
      this.$eventbus.$emit('initNotificationCount')
    },
    confirmRemovePopup(id) {
      if (!id) return false
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.remove'),
        okay: 'REMOVE',
        okayCallback: async() => {
          this.setMsgPopup()
          const { result } = await this.$api.removeNotification({
            target: this.filter,
            id: id,
          })
          if (result === 'success') {
            this.originalList = this.originalList.filter(l => l.id !== id)
            this.setList()
            this.setCount()
          }
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
    confirmRemoveAllPopup() {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.remove'),
        okay: 'REMOVE',
        okayCallback: async() => {
          this.setMsgPopup()
          const { result } = await this.$api.removeNotification({
            target: this.filter,
            id: 0,
          })
          if (result === 'success') {
            this.originalList = null
            this.setList()
            this.setCount()
          }
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
  }
}
</script>
