<template>
  <div class="filter-box hover">
    <div class="select-text" :class="{ active : getActive }">
      <template v-if="inputType === 'checkbox'">
        <span v-if="filter.length === 0">{{ title }}</span>
        <span v-else-if="opt.length > 0 && opt.find(c => c.value == filter[0])">
          {{ opt.find(c => c.value == filter[0]).text }}
          <template v-if="target.length > 1">+{{target.length-1}}</template>
        </span>
      </template>
      <template v-else>
        <span v-if="!filter && opt.length === 0">{{ title }}</span>
        <span v-else-if="opt.length > 0 && opt.find(c => c.value == filter)">
          <template v-if="isSetDefaultValue">{{ opt.find(c => c.value == target).text }}</template>
          <template v-else-if="opt[0].value == target">{{ title }}</template>
          <template v-else-if="opt[0].value != target">{{ opt.find(c => c.value == target).text }}</template>
        </span>
      </template>
      <div class="icon-img icon-arrow"></div>
    </div>
    <div class="list-box">
      <div class="label">
        {{ title }}
        <span v-if="inputType === 'checkbox'" class="desc"><span class="color-1">*</span> 중복선택 가능</span>
      </div>
      <div class="list col4">
        <label v-for="(item, index) in opt" :key="`${targetTitle}-${index}`" class="round-btn">
          <input :type="inputType" :name="targetTitle" :value="item.value" v-model="filter" >
          <span>{{item.text}}</span>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['opt', 'target', 'targetTitle', 'title', 'inputType', 'isSetDefaultValue'],
  data() {
    return {
      filter: this.target ? this.target : null,
    }
  },
  watch: {
    target(v) {
      this.filter = v
    },
    filter(v) {
      this.$emit('set', this.targetTitle, v)
    },
  },
  computed: {
    getActive() {
      if (this.$route.name === 'mypage/posts' && this.targetTitle === 'cateCode') return this.filter != null
      if (this.targetTitle === 'activity') return this.filter != null
      if (this.targetTitle === 'cateCode') return ![10, 20, 80, 5012].includes(this.filter)
      if (this.inputType === 'checkbox') return this.filter.length > 0
    },
  },
  methods: {
  }
}
</script>